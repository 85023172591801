/* eslint-disable */

export class BaseType {
    /**
     * @type {PaymentGatewaySDK}
     */
    sdk;

    constructor(sdk) {
        this.sdk = sdk;
    }

    /**
     * Returns axios instance
     * @returns {Promise<*>}
     */
    getAxios() {
        return this.sdk.getAxios();
    }

    /**
     * Returns current date with specific format.
     * @returns {string}
     */
    getCurrentDate() {
        let d = new Date(),
            month = '' + (d.getMonth() + 1),
            day = '' + d.getDate(),
            year = d.getFullYear();

        if (month.length < 2)
            month = '0' + month;
        if (day.length < 2)
            day = '0' + day;

        return [year, month, day].join('-');
    }

    async initPayment(configurations = {}) {
        let axios = await this.getAxios();

        let {initiate} = this.sdk.info;
        return await axios[initiate.method](initiate.path, this.sdk.withConfig(configurations));
    }

    /**
     * Transform init payment response.
     * @param success
     * @param payload
     * @returns {{success: boolean, is_live: {}|*|undefined, gateway: {}|*|undefined}}
     */
    initPaymentBuildResponse(success = true, payload = {}) {
        let gateway = this.sdk.getConfig('payment_gateway', payload && payload['gateway'] || undefined);
        let is_live = this.sdk.getConfig('is_live', 0);

        let results = {
            success, gateway, is_live,
        };

        if (success) {
            results = {
                ...results, url: payload['checkout_url'] || ""
            }
        } else {
            results = {
                ...results, message: payload['message'] || ""
            }
        }

        return results;
    }

    /**
     * Register payment response.
     * @param body
     * @returns {Promise<*>}
     */
    async registerPayment(body = {}) {
        let axios = await this.getAxios();

        let {register} = this.sdk.info;
        return await axios[register.method](register.path, this.sdk.withBaseData(body));
    }

    /**
     * Transform register payment response.
     * @param success
     * @param payload
     * @returns {{success: boolean, gateway: {}|*|undefined}}
     */
    registerPaymentBuildResponse(success = true, payload = {}) {
        let gateway = this.sdk.getConfig('payment_gateway', payload && payload['gateway'] || undefined);

        let results = {
            success, gateway,
        };

        if (success) {
            results = {
                ...results, url: payload['checkout_url'] || ""
            }
        } else {
            results = {
                ...results, message: payload['message'] || ""
            }
        }

        return results;
    }
}
